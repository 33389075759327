import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/social_links"
// import heroImg from "../assets/images/hero.svg"
// import { StaticImage } from "gatsby-plugin-image"
import Image from "gatsby-image"
import Globe from "./Globe";

// const query1=graphql`
// {
//   file(relativePath: {eq: "mens.jpg"}) {
//     childImageSharp {
//       fluid {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// }
// `
const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiHero {
        nodes {
          id
          title
          subtitle
          label
          desc
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }

          }
          link
        }
      }
    }
  `)

  

  const {allStrapiHero:{nodes:herodata}}=data;

  const{title,subtitle,desc,image,link,label}=herodata[0];
  
  return (
    <>
    <header className="hero gradient" >
   <div className="section-center hero-center">
     <article className="hero-info" style={{background:"none"}}>
       <div>
          {/* <div className="underline"></div> */}
          <h1 style={{color:"#fff"}}>{title}</h1>
          <h4 style={{color:"#eee",fontSize:"13px"}}>{subtitle}</h4>
          <p id="content" className="paracss" style={{color:"#fff", textAlign:"justify"}}>{desc}</p>
          <Link to={link} className="hero-btn" >
            {label}
          </Link>
          <SocialLinks/>
       </div>
     </article>
     
     <Image fluid={image.childImageSharp.fluid} className="hero-img vert-move" alt="mp2it home"/>

   </div>
   <Globe/>
   <div className="relative -mt-12 lg:-mt-24">
      
    </div>
  </header>
    </>
  )
}

  

export default Hero


