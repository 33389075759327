import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Title from "../components/Title"
import Seo from "../components/Seo"
// import Layout from "../components/Layout"

// import { Helmet } from "react-helmet"


const About2 = () => {
    const data = useStaticQuery(graphql`
    {
      allStrapiAbout {
        nodes {
          title
          stack {
            id
            title
          }
          info
          info2
          id
        }
      }
    }
  `)

  const {allStrapiAbout:{nodes:about}}=data

  const{info}=about[0];
  
  return (
      <>
     <Seo title="Home" description="MP2IT HOMEPAGE MP2ITHOME MP RESEARCH WORK MPRESEARCHWORK MPBS MP BUSINESS SOLUTION MPRW" />
      <section className="about-page" >
      <div  data-sal="zoom-in" data-sal-duration="1000">
      <Title title="about us"/>
       <div className="section-center">
        {/* <Image fluid={image.childImageSharp.fluid} className="about-img"/> */}
        <article className="about-text"> 
        
        <p >{info}</p>
        
        </article>
        
        
       </div>
      
       <div className="section-center">
       <article className="about-text"> 
        {/* <p style={{paddingTop:"20px"}}>{info2}</p> */}
        {/* <div className="about-stack">
          {stack.map((item)=>{
           return<span key={item.id}>
              {item.title}
           </span>
          })}

        </div> */}
        <Link to="/about" className="btn center-btn" data-sal="flip-left" data-sal-duration="500">
      more info
    </Link>
        </article>
       </div>
      </div>
      </section>
      </>

  )
}

// export const query = graphql`
//   {
//     allStrapiAbout {
//       nodes {
//         stack {
//           id
//           title
//         }
//         title
//         info
//         info2
//         image {
//           childImageSharp {
//             fluid {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//     }
//   }
// `

export default About2
