import React, { useState } from "react"
import Title from "./Title"
import {FaAngleDoubleRight } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

const query = graphql`
  {
    allStrapiJobs(sort: {fields: sort_id}) {
      nodes {
        strapiId
        company
        date
        position
        desc {
          id
          name
        }
      }
    }
  }
`

const Jobs = () => {
  const data = useStaticQuery(query);
  const { allStrapiJobs: { nodes: jobs }, } = data;

  const [value, setvalue] = useState(0);
  const { company, position, date, desc } = jobs[value];

  // console.log(company, position, date, desc);
  return <section className="section jobs" >
   <div data-sal="slide-up" data-sal-duration="1000">
   <Title title="Features" />
    <div className="jobs-center">

      {/* btn container */}

      <div className="btn-container">
        {jobs.map((item, index) => {
          return <button key={item.strapiId}
            onClick={() => setvalue(index)}
            className={`job-btn ${index === value && "active-btn"}`} >{item.company}</button>
        })}
      </div>
      {/* job info */}
       <article className="job-info">
        <h3>{position}</h3>
        <h4>{ company}</h4>
        <p className="job-date">{date}</p>
        {
          desc.map((item)=>{
            return<div key={item.id} className="job-desc">
              <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
              <p>{item.name}</p>
            </div>
          })
        }
       </article>

    </div>
    <Link to="/about" className="btn center-btn" >
      more info
    </Link>
   </div>
  </section>
}

export default Jobs
