import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/Hero"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import CallToAction from "../components/CallToAction"
import About from "../components/About2"
import { Helmet } from "react-helmet"

const IndexPage = ({data}) => {
  const {allStrapiProjects:{nodes:projects}}=data;
  return (
    <Layout>
      <Helmet>
  <script type="application/ld+json">
    {`
        {
          "@context" : "http://schema.org",
          "@type" : "LocalBusiness",
          "name" : "Team MP2IT",
          "image" : "https://api.mp2it.com/uploads/mp2it_seo2_a920b9abb1.jpg",
          "telephone" : "+91-936-870-1765",
          "email" : "info@mp2it.com",
          "address" : {
            "@type" : "PostalAddress",
            "streetAddress" : "128, 129 Krishna Plaza",
            "addressLocality" : "Krishna Nagar / Mathura",
            "addressRegion" : "India",
            "addressCountry" : "India",
            "postalCode" : "281001"
          },
          "url" : "https://www.mp2it.com/",
          "priceRange": "$$$",
          "aggregateRating" : {
            "@type" : "AggregateRating",
            "ratingValue" : "4.5",
            "bestRating" : "5",
            "ratingCount" : "119"
          },
          "review" : {
            "@type" : "Review",
            "reviewRating" : {
              "@type" : "Rating",
              "ratingValue" : "4.2",
              "bestRating" : "5"
            },
            "author": {
                      "@type": "Person",
                      "name": "mp2it"
                    }
          }
        }
      `}
  </script>
</Helmet>
       <Seo title="Home" description="MP2IT HOMEPAGE MP2ITHOME MP MPBS MP BUSINESS SOLUTION" />
      <Hero/>
      {/* <Services/> */}
      <About/>
       <Jobs/>
     <CallToAction/>
      <Projects projects={projects} title="featured projects" showLink/>
      
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiProjects(sort: {fields: sort_id}) {
      nodes {
        github
        id
        description
        title
        url
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    }
  }
`
export default IndexPage
